*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    background-color: #d2dfdf !important;
    font-family: 'Roboto', sans-serif;
    color: #EEEEEE;
    font-size: 100%;
}

.page {
    max-width: 33rem;
    margin: 0 auto;
    background-color: #282D35;
    padding-bottom: 2rem;
}

header.header {
    display: flex;
    justify-content: space-between;
    padding: 2rem ;
    align-items: center;
    background-color: #21222A;
}

header.header .logo {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    color: #61DAFB;
    font-size: 1.5rem;
    font-weight: bold;
}

header.header .logo img {
    width: 2rem;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 2rem 2rem;
    justify-content: center;
    background-image: url("resources/images/reactjs-icon-2.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 7.5rem;
}

.main h1 {
    padding-top: 1.5rem;
    font-size: 2.3rem;
}

.main ul {
    padding: 2rem 2.5rem;
}

.main ul li {
    line-height: 1.4rem;
    font-weight: 100;
    list-style: none;
    padding: 0.4rem 0;
}

.main ul li::before {
    display: inline-block;
    position: absolute;
    content: "•";
    color: #61DAFB;
    font-size: 2rem;
    font-weight: bold;
    margin-left: -1.5rem;
}